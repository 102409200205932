/*
Template Name: Hyper - Angular Js Admin Dashboard
Version: 3.0.0
Author: CoderThemes
Email: support@coderthemes.com
File: Main Css File
*/

//Core files
@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/config/saas/variables-dark';
@import '../node_modules/@thg-procure-team/procure-common-ui/resources/scss/config/saas/custom-variables-dark';
@import '../node_modules/bootstrap/scss/bootstrap';
@import 'theme-common';

// TODO: move to common-ui
.popover {
  border-color: #3e4853 !important;
  background-color: #37404a !important;
}

.btn-app-settings-toggler {
  background-color: $card-bg;
}

